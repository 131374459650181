<template>
	<libraryLayout>
		<div class="library-page">
			<div class="library_head" id="library-head">
				<v-container>
					<h2>Library</h2>
					<div class="library_nav">
						<button type="button" @click="setTab('collection')" :class="{active: activeTab === 'collection'}">Collection</button>
						<button type="button" @click="showComingSoonDialog = true"  :class="{active: activeTab === 'playlists'}">Playlists</button>
						<button type="button" @click="setTab('purchased')"  :class="{active: activeTab === 'purchased'}">Purchased</button>

						<div class="custom_switch" @click="switchFilterToggle()" :class="{'active': libraryFilter}">
							Filter
							<div class="switch_thumb"></div>
						</div>
					</div>
				</v-container>
			</div>
			<v-container class="full_page">
				<albumsFilter
					v-if="libraryFilter"
					:actions="false"
					:subgenreFilter="'dropdown'"
					:keysFilter="true"
					:filterName="'library'"
					:subgenreClear="true">
				</albumsFilter>


				<!-- ***** ALBUMS ***** -->
				<template v-if="showAlbumsOrTracks === 'albums'">
					<div class="albums_list">
						<div class="head_grid_albums" v-if="(viewAlbums === 'grid' && !mobile) || (libraryFilter && mobile)">
							<div class="select_view_albums">
								<button type="button" v-ripple class="view_list" @click="changeVisibleAlbums('list')"
								        :class="{'active': viewAlbums === 'list'}"></button>
								<button type="button" v-ripple class="view_grid" @click="changeVisibleAlbums('grid')"
								        :class="{'active': viewAlbums === 'grid'}"></button>
							</div>
							<div class="sortable">

								<span>Sort By</span>

								<v-menu
									:close-on-content-click="true"
									offset-y
									nudge-left="0"
									nudge-bottom="-3"
									origin="top center"
									content-class="yankees">

									<template v-slot:activator="{ on, attrs }">
										<button type="button" class="sort_by_btn" v-bind="attrs" v-on="on">
											<template v-if="albumsOrderBy.sort === 'title'">Title</template>
<!--											<template v-if="albumsOrderBy.sort === 'artist.name'">Artist</template>-->
											<template v-if="albumsOrderBy.sort === 'releasedAt'">Release Date</template>
											<template v-if="albumsOrderBy.sort === 'dateLiked'">Date Liked</template>
											<span></span>
										</button>
									</template>
									<v-list class="sort_by_list">
										<v-list-item v-for="(item, index) of sortByAlbums" :key="index">
											<button type="button" @click="selectSortBy(item.value)">
												{{ item.name }}
											</button>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</div>
						<headAlbums
							v-if="viewAlbums === 'list'"
							:selectViewAlbums="true"
							:which="'library'"
							:hideLike="false"
							:stateViewAlbums="viewAlbums"
							:updateViewAlbums="'collection/SET_VIEW_ALBUMS'"
							:stateOrderBy="'collection/ALBUMS_ORDER_BY'"
							:updateOrderBy="'collection/UPDATE_ALBUMS_ORDER_BY'">
						</headAlbums>

						<div class="wrap_albums" :class="[viewAlbums, {disabled: (!USER_PERMISSIONS || USER_PERMISSIONS === 'free') && albums.length}]">

							<!-- ***** GRID ALBUMS ***** -->
							<template v-if="viewAlbums === 'grid'">
								<template v-if="albumsLoading === true">
									<albumGridSkeletonLoader v-for="index in 30" :key="index" :parent="'default_grid_albums transparent'"></albumGridSkeletonLoader>
								</template>

								<template v-if="albumsLoading === false">
									<albumGrid
										v-for="album in albums"
										:key="album.id"
										:album="album"
										:discoveryStatus="DISCOVERY"
										:isFavouriteSong="'favourite'"
										:playOnlyLikedTracks="true"
										:hideLike="false"
										:playZone="albums">
									</albumGrid>
								</template>
							</template>

							<!-- ***** LIST ALBUMS ***** -->
							<template v-if="viewAlbums === 'list'">
								<template v-if="albumsLoading === true">
									<albumListSkeletonLoader :count="25" :parent="'default_list_albums transparent'"></albumListSkeletonLoader>
								</template>
								<div class="this_is_premium" v-if="(!USER_PERMISSIONS || USER_PERMISSIONS === 'free' && albums.length)">
									<span>List view is a premium feature</span>
									<router-link :to="{name: 'memberships'}" class="c2a_btn">Upgrade
										<span class="arrow">
		                                    <span class="line_svg"></span>
		                                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
		                                        <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
		                                    </svg>
                                        </span>
									</router-link>
								</div>

								<transition-group class="group-list-album" :class="{disabled: !USER_PERMISSIONS || USER_PERMISSIONS === 'free'}" name="slide"
								                  v-if="albumsLoading === false">
									<template>
										<albumList
											v-for="(album, index) in albums"
											:key="album.id"
											:albumDetails="album"
											:view="albumExpanded[album.id] ? '' : 'short'"
											@changeVisibilityStatus="changeVisibilityStatus"
											:discoveryStatus="DISCOVERY"
											:isFavouriteSong="'favourite'"
											:playOnlyLikedTracks="true"
											:index="index"
											:hideLike="false"
											:playZone="albums"
											:albumLocation="'collectionAlbums'">
										</albumList>
									</template>
								</transition-group>

							</template>
						</div>
						<template v-if="!albums.length && albumsLoading === false">
							<nothingHereYet></nothingHereYet>
						</template>
					</div>
					<pagination v-if="albums.length && countAlbums > 30"
					            :count="countAlbums"
					            :currentPage="albumsCurrentPage"
					            :commit="'collection/SET_ALBUMS_CURRENT_PAGE'">
					</pagination>
				</template>

				<!-- ***** TRACKS ***** -->
				<template v-if="showAlbumsOrTracks === 'tracks'">

					<div class="track_list">

						<div class="head_tracks_list">
							<div class="_left_">
								<div class="title_ hide_like">Title
									<div class="sort">
										<span class="material-icons" :class="{active: currentOrderBy === 'order[song.title]_asc'}" @click="orderBy('title', 'asc')">arrow_drop_up</span>
										<span class="material-icons" :class="{active: currentOrderBy === 'order[song.title]_desc'}" @click="orderBy('title', 'desc')">arrow_drop_down</span>
									</div>
								</div>
								<div class="artist_">Artist
									<div class="sort">
								<span class="material-icons" :class="{active: currentOrderBy === 'order[song.artist.name]_asc'}"
								      @click="orderBy('artist.name', 'asc')">arrow_drop_up</span>
										<span class="material-icons" :class="{active: currentOrderBy === 'order[song.artist.name]_desc'}"
										      @click="orderBy('artist.name', 'desc')">arrow_drop_down</span>
									</div>
								</div>
								<div class="released_">Released
									<div class="sort">
								<span class="material-icons" :class="{active: currentOrderBy === 'order[song.releasedAt]_asc'}"
								      @click="orderBy('releasedAt', 'asc')">arrow_drop_up</span>
										<span class="material-icons" :class="{active: currentOrderBy === 'order[song.releasedAt]_desc'}"
										      @click="orderBy('releasedAt', 'desc')">arrow_drop_down</span>
									</div>
								</div>
								<div class="bpm_">BPM
									<div class="sort">
										<span class="material-icons" :class="{active: currentOrderBy === 'order[song.bpm]_asc'}" @click="orderBy('bpm', 'asc')">arrow_drop_up</span>
										<span class="material-icons" :class="{active: currentOrderBy === 'order[song.bpm]_desc'}"
										      @click="orderBy('bpm', 'desc')">arrow_drop_down</span>
									</div>
								</div>
								<div class="key_">KEY
									<div class="sort">
										<span class="material-icons" :class="{active: currentOrderBy === 'order[song.key]_asc'}" @click="orderBy('key', 'asc')">arrow_drop_up</span>
										<span class="material-icons" :class="{active: currentOrderBy === 'order[song.key]_desc'}"
										      @click="orderBy('key', 'desc')">arrow_drop_down</span>
									</div>
								</div>
							</div>
							<div class="_right_ actions_">

							</div>
						</div>

						<template v-if="tracksLoading === true">
							<v-skeleton-loader
								type="image"
								class="item_track_list"
								v-for="index in 25"
								:key="index"
							></v-skeleton-loader>
						</template>
						<template v-if="tracksLoading === false">
							<itemTrack
								v-for="(track, index) in tracks"
								:key="track.id"
								:track="track.song"
								:hideLike="false"
								:playList="'libraryTrackList'"
								:index="index"
							></itemTrack>
						</template>
						<template v-if="!tracks.length && tracksLoading === false">
							<nothingHereYet></nothingHereYet>
						</template>
					</div>
					<pagination v-if="tracks.length && countTracks > 30"
					            :count="countTracks"
					            :currentPage="tracksCurrentPage"
					            :commit="'collection/SET_TRACKS_CURRENT_PAGE'">
					</pagination>
				</template>

			</v-container>

			<ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
		</div>
	</libraryLayout>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

import libraryLayout from "@/layouts/libraryLayout.vue";
import albumsFilter from '@/components/musicReleases/albumsFilter';

// loaders
import albumListSkeletonLoader from "@/components/small/skeleton-loaders/albumListSkeletonLoader.vue";
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader.vue";

import headAlbums from "@/components/musicReleases/headListAlbums.vue";
import albumGrid from "@/components/musicReleases/albumGrid.vue";
import albumList from "@/components/musicReleases/album.vue";
import pagination from "@/components/pagination.vue";
import nothingHereYet from "@/components/small/nothingHereYet.vue";
import itemTrack from "@/components/musicReleases/itemTrack.vue";

const ComingSoonDialog = () => import("@/components/dialogs/comingSoonDialog");

// mixins
import isMobile from "@/mixins/isMobile";
import changeVisibleAlbum from "@/mixins/changeVisibleAlbum";



export default {
	name: 'library',
	components: {
		itemTrack, nothingHereYet, albumGrid, albumGridSkeletonLoader, albumList, headAlbums, albumListSkeletonLoader, pagination,
		libraryLayout,
		albumsFilter,
		ComingSoonDialog
	},
	mixins: [
		changeVisibleAlbum,
		isMobile,
	],
	data() {
		return {
			libraryFilter: this.$store.getters["collection/LIBRARY_FILTER"],
			showComingSoonDialog: false,

			showAlbumsOrTracks: 'albums',
			albumExpanded: {},
			currentOrderBy: '',
			sortByAlbums: [
				{name: 'Title', value: 'title'},
				// {name: 'Artist', value: 'artist.name'},
				{name: 'Released', value: 'releasedAt'},
				{name: 'Date Liked', value: 'dateLiked'},
			],
		}
	},
	watch: {
		activeTab() {
			this.$store.commit('SET_ALBUM_EXPANDED', '');
			this.$store.commit('collection/SET_ALBUMS_CURRENT_PAGE', 1);
			this.$store.commit('collection/SET_TRACKS_CURRENT_PAGE', 1);
			if (this.showAlbumsOrTracks === 'albums') {
				this.selectAlbums();
			} else {
				this.selectTracks();
			}
		},
		subgenre() {
			this.$store.commit('SET_ALBUM_EXPANDED', '');
			this.$store.commit('collection/SET_ALBUMS_CURRENT_PAGE', 1);
			this.$store.commit('collection/SET_TRACKS_CURRENT_PAGE', 1);
			if (this.showAlbumsOrTracks === 'albums') {
				this.selectAlbums();
			} else {
				this.selectTracks();
			}
		},
		bpm() {
			// this.showAlbumsOrTracks = 'tracks';
			this.$store.commit('collection/SET_ALBUMS_CURRENT_PAGE', 1);
			this.$store.commit('collection/SET_TRACKS_CURRENT_PAGE', 1);
			this.visibleAlbumsOrTracks();
			this.selectTracks();
		},
		applyKeys() {
			// this.showAlbumsOrTracks = 'tracks';
			this.$store.commit('collection/SET_ALBUMS_CURRENT_PAGE', 1);
			this.$store.commit('collection/SET_TRACKS_CURRENT_PAGE', 1);
			this.visibleAlbumsOrTracks();
			this.selectTracks();
		},
		tags() {
			this.$store.commit('collection/SET_ALBUMS_CURRENT_PAGE', 1);
			this.$store.commit('collection/SET_TRACKS_CURRENT_PAGE', 1);
			if (this.showAlbumsOrTracks === 'albums') {
				this.selectAlbums();
			} else {
				this.selectTracks();
			}
		},
		viewAlbums() {
			this.$store.commit('SET_ALBUM_EXPANDED', '');
		},
		albumsCurrentPage() {
			this.selectAlbums();
			this.scrollTop();
		},
		tracksCurrentPage() {
			this.selectTracks();
			this.scrollTop();
		},
		tracksOrderBy() {
			this.$store.commit('collection/SET_ALBUMS_CURRENT_PAGE', 1);
			this.$store.commit('collection/SET_TRACKS_CURRENT_PAGE', 1);
			this.selectTracks();
		},
		albumsOrderBy() {
			console.log('albumsOrderBy', this.albumsOrderBy);
			this.$store.commit('collection/SET_ALBUMS_CURRENT_PAGE', 1);
			this.$store.commit('collection/SET_TRACKS_CURRENT_PAGE', 1);
			this.selectAlbums();
		},
	},

	computed: {
		...mapGetters({
			activeTab: 'collection/ACTIVE_TAB',
			subgenre: 'collection/SUBGENRE',
			bpm: 'collection/BPM',
			keys: 'collection/KEYS',
			applyKeys: 'collection/APPLY_KEYS',
			tags: 'collection/TAGS',
			viewAlbums: 'collection/VIEW_ALBUMS',
			// libraryFilter: 'collection/LIBRARY_FILTER',

			albums: 'collection/ALBUMS',
			countAlbums: 'collection/COUNT_ALBUMS',
			albumsCurrentPage: 'collection/ALBUMS_CURRENT_PAGE',
			albumsOrderBy: 'collection/ALBUMS_ORDER_BY', // { sort: 'releasedAt', order: 'desc'},
			albumsLoading: 'collection/ALBUMS_LOADING',

			tracks: 'collection/TRACKS',
			countTracks: 'collection/COUNT_TRACKS',
			tracksCurrentPage: 'collection/TRACKS_CURRENT_PAGE',
			tracksOrderBy: 'collection/TRACKS_ORDER_BY',
			tracksLoading: 'collection/TRACKS_LOADING',
		}),
		...mapGetters([
			'USER_PERMISSIONS',
			'STATUS_FILTER',
			'FAVOURITE_MUSIC_RELEASE',
			'COUNT_FAVOURITE_MUSIC_RELEASE',
			'FAVOURITE_TRACKS',
			'DISCOVERY',
		]),
	},
	created() {
		// this.mobile = this.isMobile();
		this.showAlbumsOrTracks = 'albums';
		// this.$store.commit('SET_ALBUM_EXPANDED', '');
		// this.$store.commit('collection/SET_VIEW_ALBUMS', 'grid');
	},
	mounted() {
		this.libraryFilter = this.$store.getters["collection/LIBRARY_FILTER"];
		this.currentOrderBy = `${Object.keys(this.albumsOrderBy)}_${Object.values(this.albumsOrderBy)}` //  { "order[bpm]": "asc" }
		this.visibleAlbumsOrTracks();

		let refreshReleases = this.$store.getters['REFRESH_RELEASES'];
		console.log('refreshReleases=', refreshReleases);
		if (!this.albums.length || refreshReleases) {
			this.selectAlbums();
		}
	},

	methods: {
		...mapActions({
			getAlbums: 'collection/GET_ALBUMS',
			getTracks: 'collection/GET_TRACKS'
		}),

		switchFilterToggle() {
			this.libraryFilter = !this.libraryFilter;
			this.$store.commit('collection/SET_LIBRARY_FILTER', this.libraryFilter);

			if (!this.libraryFilter) {
				this.$store.dispatch('collection/CLEAR_FILTERS');
			}
		},
		setTab(tab){
			this.$store.commit('collection/SET_ACTIVE_TAB', tab);
		},
		visibleAlbumsOrTracks() {
			if (this.bpm.length || this.keys.length) {
				this.showAlbumsOrTracks = 'tracks';
			} else {
				this.showAlbumsOrTracks = 'albums';
			}
		},
		scrollTop() {
			this.$nextTick(() => {
				const el = document.getElementById('library-head');
				if (el) {
					el.scrollIntoView({behavior: 'smooth'});
				}
			});
		},
		changeVisibleAlbums(value) {
			this.$store.commit('collection/SET_VIEW_ALBUMS', value);
		},
		selectAlbums() {
			let params = {};
			if (this.activeTab === 'purchased') {
				params['isPurchased'] = true;
			}
			if (this.subgenre.id) {
				params['subGenres'] = this.subgenre.id;
			}
			if (this.tags.length) {
				params['multiple_search_musicRelease.tags'] = Array(this.tags);
			}
			params['page'] = this.albumsCurrentPage;

			const releaseParams = Object.assign(params, this.albumsOrderBy);
			this.getAlbums(releaseParams)
				.then(() => {
				})
				.catch(err => {
					console.error(`getFavoriteAlbums, ${err}`);
				})
		},
		selectTracks() {
			let params = {};
			if (this.activeTab === 'purchased') {
				params['isPurchased'] = true;
			}
			if (this.subgenre.id) {
				params['musicRelease.subGenres.name'] = this.subgenre.name;
			}
			if (this.bpm.length) {
				params['song.bpm[between]'] = `${this.bpm[0]}` + '..' + `${this.bpm[1]}`;
			}
			if (this.tags.length) {
				// params['multiple_search_song.playLists.musicRelease.tags'] = Array(this.tags);
			}
			if (this.applyKeys.length) {
				let keys;
				if (this.applyKeys && Array.isArray(this.applyKeys)) {
          keys = this.applyKeys.reduce((acc, key, index) => {
            acc[`song.key[${index + 1}]`] = key.id;
            return acc;
          }, {})
				}
        params = Object.assign(params, keys);
			}
			params['page'] = this.tracksCurrentPage;

			const trackParams = Object.assign(params, this.tracksOrderBy);
			this.getTracks(trackParams)
				.then(() => {

				})
				.catch(err => console.error(`getTracks, ${err}`))

		},

		orderBy(key, direction) {
			console.log('run library.vue orderBy');
			this.currentOrderBy = `order[${'song.' + key}]_${direction}`;
			let isFavoriteTracks = `song.`;
			this.$store.commit('collection/SET_TRACKS_ORDER_BY', {[`order[${isFavoriteTracks}${key}]`]: `${direction}`})
		},
		selectSortBy(selectSortByValue) {
			this.$store.commit('collection/SET_ALBUMS_ORDER_BY', {
				sort: `${selectSortByValue}`,
				order: 'desc'
			});
		},
	}
};
</script>

<style lang="scss">
	.library-layout {
		height: 100%;
	}
</style>
