export default {
    methods: {
        changeVisibilityStatus(id) {
            // console.log(1);
            // let [album] = Object.keys(this.albumExpanded).filter(expanded => this.albumExpanded[expanded]);
            // if (+id !== +album) {
            //     this.albumExpanded[album] = false;
            // }
            // this.albumExpanded[id] = !this.albumExpanded[id];
        },
    }
}